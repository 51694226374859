/** @jsx jsx */
import { jsx, Flex, Box, Container, Grid, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Link from '../components/Link';
import Logo from './Logo';
import Navigation from './Navigation';
import Search from './Search';
import NavigationToggle from './NavigationToggle';
import BasketButton from './BasketButton';
import NavigationActionLink from './NavigationActionLink';
import UserMenu from './UserMenu';
import { UserIcon } from './Icon';
import Spinner from './Spinner';
import LanguageSelector from './LanguageSelector';
import { isBrowser } from '../utils';
import { getLinkForPage } from '../utils/links';
import { useNavigation } from '../hooks/useNavigation';
import { useColors } from '../hooks/useColors';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Themed } from '@theme-ui/mdx';
import Button from './Button';
import LanguageDropdown from './LanguageDropdown';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { Global } from '@emotion/react';
import { getLuminance } from 'polished';

const isMhCom = process.env.GATSBY_SITE_DOMAIN === 'com';

const imageStyles = {
  FULL_WIDTH: 'Full width',
  NEXT_TO_HEADLINE: 'Next to headline',
};

const isHighLuminance = (color) => {
  try {
    return getLuminance(color) === 1;
  } catch {
    return false;
  }
};

const MenuWrapper = (props) => (
  <Flex
    sx={{
      position: ['fixed', null, null, 'static'],
      top: '4rem',
      left: 0,
      bottom: 0,
      gap: isMhCom ? 4 : 0, // element gap
      zIndex: 100,
      flexDirection: ['column', null, null, 'row'],
      justifyContent: ['space-between', null, null, isMhCom ? 'flex-end' : 'flex-start'], // header links alignment
      alignItems: ['flex-start', null, null, 'center'],
      flex: 1,
      p: [3, 4, 3, null, 0],
      visibility: [props.open ? 'visible' : 'hidden', null, null, 'visible'],
      opacity: [props.open ? 1 : 0, 1],
      width: ['100%', null, null, 'auto'],
      bg: ['secondary', null, null, 'transparent'],
      transition: 'opacity .3s ease, visibility .3s ease',
      willChange: 'opacity, visibility',
      '> div, > nav': {
        opacity: [props.open ? 1 : 0, null, null, 1],
        transform: [props.open ? 'translateY(0)' : 'translateY(-28px)', null, null, 'none'],
      },
    }}
  >
    {props.children}
  </Flex>
);

const LinkWithNoTitle = ({ to, children }) => (
  <Link to={to} noTitleFix>
    {children}
  </Link>
);

const removeTrailingSlash = (s) => {
  return typeof s === 'string' && s.endsWith('/') ? s.substring(0, s.length - 1) : s;
};

const NavLinkWithChildPaths = ({ header, links, ...props }) => {
  const slugs = (links || []).map((l) => (l.page && getLinkForPage(l.page)) || l.url);
  const currentPath = isBrowser && window.location.pathname;
  const isCurrent = slugs.includes(removeTrailingSlash(currentPath));
  return <NavigationActionLink {...props} current={isCurrent} color={header?.navigationColor} />;
};

const Header = ({
  path,
  index,
  header,
  menu,
  setMenu,
  paths,
  showNavigation,
  navigation,
  minimal = false,
  hideLocales,
  darkMode,
}) => {
  const isComSe = (isMhCom && path && (path?.startsWith('/se/') || path === '/se')) || false;
  const { t: translate, i18n } = useTranslation();
  const hasUser = useSelector((state) => !!(state.session && state.session.user));
  const isLoadingUser = useSelector((state) => !!(state.session && state.session.isLoading));
  const numberOfBasketItems = useSelector((state) => ((state.basket && state.basket.items) || []).length);
  const locale = i18n.language;
  const { navigation: defaultNavLinks, business: businessLinks, matkahuoltoComMain } = useNavigation(locale, isComSe);
  const { lowContrast, hiContrast } = useColors(darkMode);

  const { width: windowWidth } = useWindowDimensions();

  const { theme } = useThemeUI();
  const breakpoint = parseFloat(theme?.breakpoints?.[2] || '62') * 16;

  const [useDropdownLanguage, setUseDropdownLanguage] = useState(isMhCom);
  useEffect(() => {
    setUseDropdownLanguage(isMhCom && windowWidth > breakpoint);
  }, [windowWidth]);

  const logoLink = isMhCom && isComSe ? '/se' : '/';

  return (
    <Box
      as="header"
      sx={{
        position: header ? 'relative' : menu ? ['fixed', null, null, 'relative'] : ['sticky', null, null, 'relative'],
        display: 'flex',
        flexDirection: 'column',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        minHeight: header && [null, null, null, 700],
        pt: [12, 3],
        backgroundColor: menu
          ? ['secondary', null, null, lowContrast]
          : header
            ? header?.backgroundColor || 'primary'
            : lowContrast,
        mb: header && !index ? 5 : 0,
      }}
    >
      <Global styles={{ body: isMhCom && menu ? { overflow: 'hidden' } : {} }} />
      {header && header.imageStyle === imageStyles.FULL_WIDTH && !menu ? (
        <GatsbyImage
          image={getImage(header.fullWidthImg)}
          alt={header?.fullWidthImg?.description || header.headline}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: '15% 15%',
          }}
        />
      ) : null}

      <Container
        variant="wide"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          px: [3, null, null, 4],
          pb: isMhCom ? 3 : [12, null, null, showNavigation ? 5 : 3],
        }}
      >
        <Box
          sx={{
            width: ['40%', null, null, 'auto'],
            justifySelf: 'flex-start',
          }}
        >
          <NavigationToggle
            color={header?.navigationColor}
            darkMode={darkMode}
            close={menu}
            onClick={() => {
              setMenu(!menu);
              if (menu) return;
            }}
          />
        </Box>
        <Box
          as={LinkWithNoTitle}
          to={logoLink}
          sx={{
            flex: 'none',
            width: [74, null, null, 86],
            mr: [0, null, null, 3],
          }}
        >
          <Logo
            sx={{
              width: 'auto',
              height: [48, null, null, 64],
              mt: [0, null, null, 1],
              color: menu
                ? ['white', null, null, !darkMode ? 'logoNewDarkBlue' : 'white']
                : !darkMode
                  ? header?.textColor || hiContrast
                  : 'white',
            }}
          />
        </Box>

        <MenuWrapper open={menu} setMenu={setMenu}>
          {!minimal && (
            <Flex
              sx={{
                flex: 'none',
                mt: [4, null, null, 0],
                order: [1, null, null, 0],
                ml: [0, null, null, 2],
              }}
            >
              {isMhCom ? (
                <>
                  {matkahuoltoComMain.map((navLink) => (
                    <NavigationActionLink
                      to={getLinkForPage(navLink.page)}
                      darkMode={darkMode}
                      color={header?.navigationColor || 'primary'}
                    >
                      {navLink.title}
                    </NavigationActionLink>
                  ))}
                </>
              ) : (
                <>
                  <NavLinkWithChildPaths to="/" links={defaultNavLinks} darkMode={darkMode} text header={header}>
                    {translate('navigation.private')}
                  </NavLinkWithChildPaths>
                  <NavLinkWithChildPaths to="/yrityksille" links={businessLinks} darkMode={darkMode} header={header}>
                    {translate('navigation.business')}
                  </NavLinkWithChildPaths>
                  <NavigationActionLink to="/palvelupisteet" darkMode={darkMode} color={header?.navigationColor}>
                    {translate('navigation.servicePoints')}
                  </NavigationActionLink>
                </>
              )}
            </Flex>
          )}

          {!minimal && !isMhCom ? <Search sx={{ order: [0, null, null, 1] }} darkMode={darkMode} /> : null}

          {!isMhCom ? (
            <Navigation
              paths={paths}
              navigation={navigation}
              darkMode={darkMode}
              color={header?.navigationColor}
              sx={{
                display: showNavigation ? 'flex' : ['flex', null, null, 'none'],
                order: [2, null, null, 2],
                position: ['static', null, null, 'absolute'],
                bottom: 3,
                left: [3, null, null, 4],
              }}
            />
          ) : null}

          {!!minimal && <Box sx={{ flexGrow: 1, order: 3 }} />}

          {!minimal ? (
            <>
              {useDropdownLanguage ? (
                <LanguageDropdown
                  color={header?.navigationColor}
                  darkMode={darkMode}
                  hideLocales={hideLocales}
                  paths={paths}
                  sx={{ zIndex: 6, order: [4, null, null, 3] }}
                />
              ) : (
                <LanguageSelector
                  color={header?.navigationColor}
                  darkMode={darkMode}
                  hideLocales={hideLocales}
                  paths={paths}
                  sx={{ order: [4, null, null, 3] }}
                />
              )}
            </>
          ) : (
            <NavigationActionLink sx={{ order: 3 }} to="/" darkMode={darkMode}>
              {isMhCom ? 'Matkahuolto.com' : 'Matkahuolto.fi'} &rarr;
            </NavigationActionLink>
          )}
        </MenuWrapper>

        {!!minimal && <Box sx={{ width: ['40%', null, null, 'auto'] }} />}

        {!minimal && (
          <Flex
            sx={{
              width: ['40%', null, null, 'auto'],
              justifyContent: 'flex-end',
            }}
          >
            {!isMhCom ? (
              <>
                {!hasUser &&
                  (isLoadingUser ? (
                    <Box sx={{ position: 'relative', width: ['64px', null, '96px'] }}>
                      <Spinner />
                    </Box>
                  ) : (
                    <NavigationActionLink
                      to="/login"
                      darkMode={darkMode}
                      sx={{
                        color: menu ? ['white', null, null, hiContrast] : header?.navigationColor || hiContrast,
                      }}
                    >
                      <UserIcon sx={{ mr: [0, 2], color: header?.navigationColor || hiContrast }} />
                      <Box
                        sx={{ display: ['none', null, null, 'block'], color: header?.navigationColor || hiContrast }}
                      >
                        {translate('page.login')}
                      </Box>
                    </NavigationActionLink>
                  ))}
                {hasUser && <UserMenu darkMode={darkMode} header={header} />}
                {numberOfBasketItems ? (
                  <BasketButton
                    numberOfItems={numberOfBasketItems}
                    color={menu ? ['white', null, null, hiContrast] : hiContrast}
                  />
                ) : null}
              </>
            ) : null}
          </Flex>
        )}
      </Container>
      {header && (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Container>
            <Grid
              sx={{
                gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
                gridGap: [2, 2, 2, 6],
                alignItems: 'center',
              }}
            >
              {header.imageStyle === imageStyles.NEXT_TO_HEADLINE && (
                <GatsbyImage
                  image={getImage(header.constrainedImg)}
                  alt={header?.constrainedImg?.description || header.headline}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  sx={{
                    order: [0, null, null, 1],
                    borderRadius: 1,
                    mx: [-3, null, null, 0],
                    bg: 'primary',
                  }}
                />
              )}

              <Box sx={{ order: [1, null, null, 0], mb: [4, null, null, 1], mt: [3, null, null, 1] }}>
                <Themed.h1
                  lang={locale}
                  sx={{
                    m: 0,
                    hyphens: 'auto',
                    color: header.textColor || 'white',
                    wordBreak: 'break-word',
                    fontSize:
                      header?.imageStyle === imageStyles.NEXT_TO_HEADLINE ? [50, null, null, 70] : [50, null, null, 70],
                  }}
                >
                  {header.headline}
                </Themed.h1>

                {header.description && (
                  <p sx={{ color: header.textColor || 'white', fontSize: [3, null, null, 4] }}>{header.description}</p>
                )}
                {header.ctaLink && header.ctaText ? (
                  <Button
                    as={Link}
                    variant="secondary"
                    sx={{
                      mt: header.description ? [2, 2, 2, 3] : 4,
                      px: 4,
                      border: header?.backgroundColor && isHighLuminance(header?.backgroundColor) ? undefined : 0,
                      color: 'primary',
                    }}
                    to={header.ctaLink}
                  >
                    {header.ctaText}
                  </Button>
                ) : null}
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default Header;
